// CSS Reset
@import "./normalize.scss";

// Constants
$LOGO_BLUE: #02b3e4;
$NAV_HEIGHT: 60px;

// mixins
@mixin hideScrollBars {
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}
// Global CSS here
.nav {
	background: rgb(255, 255, 255);
	height: $NAV_HEIGHT;
	box-shadow: 0 3.4px 6.1px rgba(0, 0, 0, 0.008),
		0 9.5px 16.9px rgba(0, 0, 0, 0.011), 0 22.9px 40.7px rgba(0, 0, 0, 0.013),
		0 76px 135px rgba(0, 0, 0, 0.02);
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 999;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 0em;

	#logo {
		width: 35px;
		height: 35px;
		fill: $LOGO_BLUE;
		cursor: pointer;
	}

	a {
		padding: 0 1em;
	}

	.active {
		color: $LOGO_BLUE;
	}

	.websiteName {
		font-size: 1.5em;
		text-transform: capitalize;
		font-weight: bold;
		cursor: pointer;
		color: rgb(27, 28, 31);
		margin-left: -0.5em;
	}

	.spacer {
		flex-grow: 2;
	}

	.githubLink {
		width: 30px;
		height: 30px;
		margin-right: 1em;
	}
}

.app {
	background: #edf2f6;
	padding-top: 1em;
	@include hideScrollBars;
}

.inputBox {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	margin: 1em 0;
	width: 90%;

	textarea {
		background: #fff;
		box-shadow: 0 0px 15.2px rgba(0, 0, 0, 0.021),
			0 0px 121px rgba(0, 0, 0, 0.04);
		padding: 1em;
		width: 100%;
	}

	button {
		width: 100%;
		margin: 0.5em auto;
		background: #d1dbe0;
	}
}

.leftContainer,
.rightContainer {
	height: 100vh;
	max-height: calc(100vh - 60px);
	overflow-y: auto;
	@include hideScrollBars;
}

.rightContainer {
	padding-left: 0.5em;
}

.feedback_container {
	.feedback {
		background: rgb(255, 255, 255);
		border-radius: 8px;
		margin: 1em;
		padding: 1em 0;
		border: 1.5px rgb(222, 233, 243) solid;
		box-shadow: 0 3.4px 6.1px rgba(0, 0, 0, 0.008),
			0 9.5px 16.9px rgba(0, 0, 0, 0.011), 0 22.9px 40.7px rgba(0, 0, 0, 0.013),
			0 76px 135px rgba(0, 0, 0, 0.02);
		position: relative;
		.review_link {
			position: absolute;
			right: 1em;
			top: 1.2em;
		}
		.project_name {
			color: $LOGO_BLUE;
			// color: #053872;

			cursor: pointer;
		}
		.student_comment {
			font-size: 0.9em;
			color: rgb(71, 69, 69);
		}

		.feedback_footer {
			small {
				color: rgb(94, 100, 104);
				cursor: pointer;
			}
			padding: 1em 1em 0 1em;
		}
	}
}

.disclaimer_container {
	background: rgb(254, 242, 229);
	padding: 1.8em;
	margin: 1.8em;
	border-radius: 8px;

	h1 {
		font-size: 2em;
		font-weight: bold;
	}

	h2 {
		font-weight: bold;
	}

	a {
		color: $LOGO_BLUE;
	}

	ol {
		padding-left: 2em;
	}
}

.footer {
	text-align: center;
	padding: 0.5em;
	box-shadow: 0 3.4px 6.1px rgba(0, 0, 0, 0.008),
		0 9.5px 16.9px rgba(0, 0, 0, 0.011), 0 22.9px 40.7px rgba(0, 0, 0, 0.013),
		0 76px 135px rgba(0, 0, 0, 0.02);
}
